import { ICustomerModel } from "../customers/customer.model";
import { InventoryLineItemModel } from "../inventory/inventory-line-item.model";
import { InvoiceModel } from "../invoices/invoice.model";
import { AddressModel } from "../latham-ordering/address.model";
import { InventoryWarehouseModel } from "../lookups/inventory-warehouse.model";
import { ShippingAddressModel } from "../shipping-address/shipping-address.model";

export interface IShippingRecordModel {
    shippingRecordId: number;
    proNumber: string;
    estimatedShipDate?: Date;
    actualShipDate?: Date;
    shippedBy: string;
    //inventoryShipToWarehouseId?: number;
    inventoryShipFromWarehouseId?: number;
    inventoryShipFromWarehouseName: string;
    inventoryLineItemModels: InventoryLineItemModel[];
    invoiceId?: number;
    invoiceModel: InvoiceModel;
    shipped: boolean;
    carrierId: number;
    carrierName: string;
    notes: string;
    shipToFirstName: string;
    shipToLastName: string;

    shippingAddress: ShippingAddressModel;
    inventoryShipToWarehouse: InventoryWarehouseModel;

}

export class ShippingRecordModel implements IShippingRecordModel {
    constructor() {
        this.shippingAddress = new ShippingAddressModel();
        this.inventoryLineItemModels = [];
        this.shipped = false;
        this.invoiceId = null;
        //this.inventoryShipToWarehouseId = 0;
    }

    shippingRecordId: number;
    proNumber: string;
    estimatedShipDate?: Date;
    actualShipDate?: Date;
    shippedBy: string;
    //inventoryShipToWarehouseId?: number;
    inventoryShipFromWarehouseId?: number;
    inventoryShipFromWarehouseName: string;
    inventoryLineItemModels: InventoryLineItemModel[];
    invoiceId?: number;
    invoiceModel: InvoiceModel;
    shipped: boolean;
    carrierId: number;
    carrierName: string;
    notes: string;
    shipToFirstName: string;
    shipToLastName: string;

    shippingAddress: ShippingAddressModel;
    inventoryShipToWarehouse: InventoryWarehouseModel;
}